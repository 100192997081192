import React, { useState, useEffect } from 'react';

import './FlipClock.css';

const FlipClock = ({ timeLeft }) =>
{
    const [time, setTime] = useState(timeLeft);

    useEffect(() =>
    {
        const interval = setInterval(() =>
        {
            setTime(prev => prev > 0 ? prev - 1 : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [timeLeft]);

    const formatTime = (time) =>
    {
        const hours = String(Math.floor(time / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0');
        const seconds = String(time % 60).padStart(2, '0');

        return { hours, minutes, seconds };
    };

    const { hours, minutes, seconds } = formatTime(time);

    return (
        <div className='flip-clock'>
            <div className='flip-clock-card'>{hours}</div>
            <div className='flip-clock-card'>{minutes}</div>
            <div className='flip-clock-card'>{seconds}</div>
        </div>
    );
};

export default FlipClock;