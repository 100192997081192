import React, { useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import { HomeOutlined, FileTextOutlined, 
    AppstoreOutlined, MenuOutlined } from '@ant-design/icons';

import DailyCombo from './DailyCombo';
import TasksTable from './TasksTable';

import './Dashboard.css';

const { Header, Sider, Content } = Layout;

function Dashboard({ sessionToken }) 
{
    const [collapsed, setCollapsed] = useState(true);
    const [selectedSection, setSelectedSection] = useState("combo");

    const toggleMenu = () =>
    {
        setCollapsed(!collapsed);
    };

    const handleSectionSelect = (section) =>
    {
        setSelectedSection(section);

        if (window.innerHeight < 768)
        {
            setCollapsed(true);
        }
    }

    const closeMenu = () =>
    {
        if (!collapsed)
        {
            setCollapsed(true);
        }
    };

    return (
        <Layout className="dashboard-layout" onClick={closeMenu}>
            {/* Боковая панель меню */}

            <Sider 
                collapsible
                collapsed={collapsed}
                breakpoint="lg"
                collapsedWidth="0"
                trigger={null}
                wdith="350"
                className={collapsed ? "sider-collapsed": "sider-expanded"}
                onClick={(e) => e.stopPropagation()}>

                <div className="menu-header">
                    PROJECT VIRUS
                </div>

                <Menu theme="dark" mode="inline" 
                    defaultSelectedKeys={['3']} className="custom-menu">
                    <Menu.Item key="1" icon={<HomeOutlined/>} 
                        onClick={() => handleSectionSelect("home")}>
                        Общая статистика
                    </Menu.Item>
                    <Menu.Item key="2" icon={<FileTextOutlined/>}
                        onClick={() => handleSectionSelect("tasks")}>
                        Доступные таски
                    </Menu.Item>
                    <Menu.Item key="3" icon={<AppstoreOutlined/>}
                        onClick={() => handleSectionSelect("combo")}>
                        Текущее комбо
                    </Menu.Item>
                </Menu>
            </Sider>

            {/* Основное содержимое */}
            <Layout>
                <Header className="header">
                    <h1>
                        {
                            selectedSection === "combo" ? "ЕЖЕДНЕВНОЕ КОМБО" :
                            selectedSection === "home" ? "ОБЩАЯ СТАТИСТИКА" : "ДОСТУПНЫЕ ТАСКИ"
                        }
                    </h1>
                    {collapsed && 
                    (
                        <Button type="primary" icon={<MenuOutlined/>} 
                            onClick={toggleMenu} className="burger-menu-button"/>
                    )}
                </Header>

                <Content className="content-container">
                    { selectedSection === "combo" && 
                        <DailyCombo sessionToken={sessionToken}/> }
                    { selectedSection === "tasks" && <TasksTable sessionToken={sessionToken}/> }
                </Content>
            </Layout>
        </Layout>
    );
}

export default Dashboard;