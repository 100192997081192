import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Upload,
  Spin,
  message,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CloseOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import config from "./config";

import "./TasksTable.css";

const TasksTable = ({ sessionToken }) => {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState("Invite");

  const [form] = Form.useForm();

  useEffect(() => {
    loadTasks();
  }, []);

  const markTaskAsFinished = async (taskId) => {
    try {
      const response = await fetch(
        `${config.FULL_API_URL}/api/v1/virus-game/tasks/delete/${taskId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
          body: JSON.stringify({ isFinished: true }),
        }
      );

      if (!response.ok)
      {
        message.error("Не удалось отметить таску, как удалённую");

        return;
      }

      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));

      message.success(`Таска ${taskId} успешно удалена, 
        данные с сервером синхронизированы`);
    }
    catch (error)
    {
      console.error("Failed to delete task, reason: ", error);

      message.error(`Не удалось удалить таску: ${taskId}`)
    }
  };

  const loadTasks = async () => {
    try {
      const response = await fetch(
        `${config.FULL_API_URL}/api/v1/virus-game/tasks`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      const loadedTasksData = await response.json();

      if (loadedTasksData.isSuccess) {
        const mappedTasks = loadedTasksData.tasks
          .filter((task) => !task.isFinished)
          .map((task) => ({
            id: task.id,
            header: task.header || "Без имени",
            description: task.description || "",
            type: task.type || "Invite",
            delay: task.delay || 0,
            reward: task.reward || 0,
            iconUrl: task.iconUrl || null,
            additionalValue: task.additionalValue || "",
            actionUrl: task.actionUrl || "",
            isFinished: task.isFinished || false,
        }));

        setTasks(mappedTasks);

        message.success("Таски успешно загружены!");

        return;
      }

      console.error(`Failed to fetch game tasks`);

      message.error("Не удалось получить таски c сервера");
    } 
    catch (error) {
      console.error(`Failed to fetch game tasks`, error);

      message.error(
        `Ошибка загрузки тасок c сервера, причина: ${JSON.stringify(error)}`
      );
    } 
    finally {
      setIsLoading(false);
    }
  };

  const addTask = () => {
    form.resetFields();
    form.setFieldsValue({
      reward: 0,
      type: "Invite",
    });
    setSelectedTaskType("Invite");

    setEditingTask(null);
    setIsModalVisible(true);
  };

  const editTask = (task) => {
    const isIconValid = task?.iconUrl && 
      typeof task.iconUrl === "string" &&
      (task.iconUrl.startsWith("http") || 
      task.iconUrl.startsWith("https"));

    const mappedTask = {
      header: task.header,
      description: task.description,
      type: task.type,
      delay: task.delay,
      reward: task.reward,
      image: isIconValid ?
      [
        {
          uid: '-1',
          name: 'icon',
          status: 'done',
          url: task.iconUrl,
        },
      ] : [],
      additionalValue: task.additionalValue || "",
      actionUrl: task.actionUrl || ""
    };

    form.setFieldsValue(mappedTask);

    setSelectedTaskType(task.type);
    setEditingTask(task);
    setIsModalVisible(true);
  };

  const saveTask = async () => {
    try {
      const values = await form.validateFields();
      const image = values.image;

      let imageUrl = editingTask?.iconUrl;

      if (image && image[0]?.originFileObj)
      {
        if (image[0]?.originFileObj.size > 10 * 1024 * 1024)
        {
          message.error("Размер файла превышает допустимый лимит в 10МБ.");
            
          return;
        }

        const formData = new FormData();

        formData.append("file", image[0].originFileObj);

        const response = await fetch(
          `${config.FULL_API_URL}/api/v1/virus-game/tasks/upload-icon`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
            body: formData,
          }
        );

        if (!response.ok)
        {
            message.error(`Не удалось загрузить создать иконку для таски` +
              `'${editingTask?.header || ""} на сервере!`)

            return;
        }

        message.success(`Новая иконка для таски '${editingTask?.header || ""}' успешно создана!`);

        const data = await response.json();

        imageUrl = data.iconUrl;

        console.log(`Parsed new icon url: ${imageUrl}`);
      }

      let newId = editingTask?.id;

      if (!editingTask)
      {
        const response = await fetch(
          `${config.FULL_API_URL}/api/v1/virus-game/tasks`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );

        const loadedTasksData = await response.json();

        if (loadedTasksData.isSuccess)
        {
          const existingIds = loadedTasksData.tasks.map((task) => task.id);

          newId = Math.max(...existingIds, 0) + 1;
        }
        else
        {
          console.error("Failed to fetch tasks list");

          return;
        }
      }

      var newTask = {
        ...values,
        id: newId,
        iconUrl: imageUrl,
        additionalValue: values.additionalValue || "",
        actionUrl: values.actionUrl || ""
      };

      delete newTask.image;

      var typeForReward;

      if (values.type === "Invite")
      {
        typeForReward = "Invite";
      }
      else if (values.type === "Subscribe")
      {
        typeForReward = "Subscribe telegram";
      }

      if (!editingTask)
      {
        console.log(`Start fetching new task value...
          Image url: ${imageUrl}`);

        newTask = {
          ...values,
          id: newId,
          iconUrl: imageUrl,
          additionalValue: values.additionalValue || "",
          typeForReward,
          actionUrl: values.actionUrl || ""
        };

        delete newTask.image;

        console.log(`Data for new task: ${JSON.stringify(newTask)}`);
      }

      const method = editingTask ? "PUT" : "POST";
      const url = editingTask
        ? `${config.FULL_API_URL}/api/v1/virus-game/tasks/update/${editingTask.id}`
        : `${config.FULL_API_URL}/api/v1/virus-game/tasks/add`;

      console.log(`API method for add task: ${method}`);

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(newTask),
      });

      if (!response.ok)
      {
        console.error(`Failed to update or add task data`);

        message.error("Не удалось синхронизировать добавленную информацию с сервером");

        throw new Error("Failed to update or add task data");
      }

      const { updatedTask: returnedTask } = await response.json();

      if (editingTask) {
        setTasks((previousTasks) =>
            previousTasks.map((task) =>
                task.id === editingTask.id ? 
                {
                  ...task, ...returnedTask, iconUrl: imageUrl
                }
                : task
          )
        );

        message.success(`Таска '${values.header}' была успешно изменена`);
      }
      else {
        setTasks((prevTasks) => [...prevTasks,
          {
            ...returnedTask, iconUrl: imageUrl
          }]);

        message.success(`Таска '${values.header}' была успешно создана`);
      }

      setIsModalVisible(false);
    } 
    catch (error) {
      console.error("Ошибка при сохранении данных:", error);

      message.error(`Не удалось сохранить таску, причина: ${error}`);
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "iconUrl",
      key: "iconUrl",
      render: (image) => {
        return image ? (
          <img
            src={image}
            alt="task"
            style={{ width: 50, height: 50, borderRadius: "8px"  }}
            onError={(e) => {
              e.target.src = "logo192.png";
            }}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ccc",
              color: "black",
            }}
          >
            <span>Нет</span>
          </div>
        );
      },
    },
    {
      title: "ИМЯ",
      dataIndex: "header",
      key: "header",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "ТИП ТАСКИ",
      dataIndex: "type",
      key: "type",
      render: (type) => (type === "Invite" ? "ИНВАЙТ" : "ПОДПИСКА"),
    },
    {
      title: "РАЗМЕР НАГРАДЫ",
      dataIndex: "reward",
      key: "reward",
      render: (reward) => `${reward} КРИСТАЛЛОВ`,
    },
    {
      title: "ДЕЙСТВИЯ",
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            icon={<EditOutlined />}
            onClick={() => editTask(record)}
            className="edit-button"
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => markTaskAsFinished(record.id)}
            className="delete-button"
          />
        </div>
      ),
    },
  ];

  return (
    <div className="tasks-container">
      {isLoading ? (
        <div className="loading-overlay">
          <Spin tip="Загрузка тасков..." style={{ marginTop: 20 }} />
        </div>
      ) : (
        <>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addTask}
            className="add-button"
          >
            Добавить
          </Button>
          <Table
            dataSource={tasks}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 7 }}
            className="tasks-table"
            locale={{ emptyText: "Нет доступных задач" }}
          />
        </>
      )}
      <Modal
        title={
          <div className="modal-title">
            {editingTask ? "РЕДАКТИРОВАНИЕ" : "НОВАЯ ТАСКА"}
          </div>
        }
        open={isModalVisible}
        onOk={saveTask}
        onCancel={() => setIsModalVisible(false)}
        okText="Сохранить"
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
        className="custom-modal"
        footer={[
          <Button key="save" type="primary" onClick={saveTask}>
            Сохранить
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="image"
            label="Иконка"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) return e;

              return e && e.fileList;
            }}
          >
            <Upload listType="picture" beforeUpload={() => false} maxCount={1}>
              <Button icon={<UploadOutlined />}>Загрузить</Button>
            </Upload>
          </Form.Item>
          <div syle={{ display: "flex", gap: "16px" }}>
            <Form.Item
              name="header"
              label="ИМЯ"
              rules={[{ required: true, message: "Введите имя таски" }]}
            >
              <Input placeholder="Введите имя задачи" />
            </Form.Item>
            <Form.Item name="description" label="ОПИСАНИЕ">
              <Input.TextArea placeholder="Введите описание таски" rows={4} />
            </Form.Item>
          </div>
          <div syle={{ display: "flex", gap: "16px" }}>
            <Form.Item
              name="type"
              label="ТИП ТАСКИ"
              rules={[{ required: true, message: "Выберите тип таски" }]}
            >
              <Select
                placeholder="Выберите тип"
                onChange={(value) => setSelectedTaskType(value)}
              >
                <Select.Option value="Invite">ИНВАЙТ</Select.Option>
                <Select.Option value="Subscribe">ПОДПИСКА</Select.Option>
              </Select>
            </Form.Item>
            {selectedTaskType === "Subscribe" && (
            <Form.Item
                name="actionUrl"
                label="ССЫЛКА НА ТГ КАНАЛ"
                rules={[
                  { 
                    required: true,
                    message: "Введите ссылку на канал"
                  },
                {
                  type: "url",
                  message: "Введите корректный URL",
                },
                {
                  validator: (_, value) =>
                    value && /^https:\/\/t\.me\/[\w\d_]+$/.test(value)
                  ? Promise.resolve()
                  : Promise.reject(new Error("Введите корректную ссылку на Telegram канал")),
                },
              ]}
            >
              <Input
                placeholder="Введите ссылку на канал"
              />
            </Form.Item>
            )}
            {selectedTaskType === "Subscribe" && (
            <Form.Item
                name="additionalValue"
                label="ЮЗЕРНЕЙМ КАНАЛА"
                rules={[
                  {
                    required: true,
                    message: "Введите юзернейм канала"
                  },
                  {
                    pattern: /^[a-zA-Z0-9_]{5,32}$/,
                    message:
                    "Юзернейм должен содержать от 5 до 32 символов: латинские буквы, цифры или _",
                  },
                ]}
            >
              <Input
                placeholder="Введите юзернейм канала"
              />
            </Form.Item>
            )}
            {selectedTaskType === "Subscribe" && (
            <Form.Item
                name="delay"
                label="ЗАДЕРЖКА НАЧИСЛЕНИЯ (В СЕКУНДАХ)"
                rules={[{ pattern: /^\d+$/, message: "Введите только число" }]}
            >
              <Input
                  placeholder="Введите задержку начисления награды"
                  type="number"
              />
            </Form.Item>
            )}
            {selectedTaskType === "Invite" && (
            <Form.Item
                name="additionalValue"
                label="НУЖНОЕ КОЛИЧЕСТВО ДРУЗЕЙ"
                rules={[{ pattern: /^\d+$/, message: "Введите только число" }]}
            >
              <Input
                  placeholder="Введите количество друзей"
                  name="number"
              />
            </Form.Item>
            )}
            <Form.Item
              name="reward"
              label="РАЗМЕР НАГРАДЫ"
              rules={[{ required: true, message: "Введите размер награды" }]}
            >
              <Input placeholder="Введите размер награды" type="number" />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default TasksTable;